import React from "react";
import theme from "theme";
import { Theme, Em, Text, Box, Button, Section, Icon, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				PROLEARNING
			</title>
			<meta name={"description"} content={"Створи свою кар'єру з нуля або вдоскональ свої навички з ProLearning!"} />
			<meta property={"og:title"} content={"PROLEARNING"} />
			<meta property={"og:description"} content={"Створи свою кар'єру з нуля або вдоскональ свої навички з ProLearning!"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/67448839e5d277001899b0c5/images/2462982.png?v=2024-11-25T14:37:23.573Z"} />
		</Helmet>
		<Components.Heder2 />
		<Section padding="160px 0 170px 0" background="linear-gradient(180deg,rgba(5, 7, 33, 0.9) 0%,rgba(5, 7, 33, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/67448839e5d277001899b0c5/images/security_programming_hero.jpg?v=2024-11-25T14:33:29.503Z) center/cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-8">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="7px 20px 7px 20px"
					margin="0px 0px 30px 0px"
					border-radius="25px"
					border-width="1px"
					border-style="solid"
					border-color="rgba(228, 232, 236, 0.15)"
					sm-flex-direction="column"
					justify-content="center"
				>
					<Text
						margin="0px 10px 0px 0px"
						color="#d4dae0"
						font="normal 300 16px/1.5 --fontFamily-sans"
						sm-margin="0px 0 5px 0px"
						sm-text-align="center"
					>
						<Em>
							Навчання веб-розробці для тих, хто хоче більше
						</Em>
					</Text>
				</Box>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 60px 0px 60px"
					text-align="center"
					color="--light"
					lg-padding="0px 0 0px 0"
				>
					<Em
						font="--headline1"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Контактна інформація
					</Em>
				</Text>
				<Text
					margin="0px 0px 1rem 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					<Em>
						Готові змінити своє життя?
					</Em>
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#d4dae0"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Реєструйтесь на курс уже сьогодні та зробіть перший крок до мрії разом з ProLearning24!
					</Em>
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#3f24d8"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="#3f24d8"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						type="link"
						text-decoration-line="initial"
						href="/index"
					>
						Головна
					</Button>
					<Button
						margin="0px 0 0px 0px"
						padding="12px 28px 12px 28px"
						background="rgba(0, 119, 204, 0)"
						color="#d4dae0"
						font="normal 500 17px/1.5 --fontFamily-sans"
						type="link"
						href="/contacts"
						text-decoration-line="initial"
					>
						Контакти
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="--color-dark" color="--lightD2" quarkly-title="Contacts-8">
			<Override slot="SectionContent" flex-direction="row" align-items="flex-start" lg-flex-direction="column" />
			<Box
				min-width="100px"
				min-height="100px"
				width="40%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="--headline3" color="--light">
					Зв'яжіться з нами
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="--lightD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>
					Наша команда підтримки працює{" "}
					<br />
					щодня з 09:00 до 20:00.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				margin="0px 0 0 10%"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				width="50%"
				lg-margin="0px 0 0 0"
				lg-width="100%"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:+9877654321223"
							color="--light"
							text-decoration-line="initial"
							font="--headline3"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							проспект Свободи, 5, Львів,{" "}
							<br />
							Львівська область, 79000
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:0996066677"
							color="--light"
							text-decoration-line="initial"
							font="--headline3"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							0996066677
						</Link>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Icon
						category="md"
						icon={MdMailOutline}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="info@prolearning24.com"
							color="--light"
							text-decoration-line="initial"
							font="--headline3"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							info@prolearning24.com
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1">
				0996066677
				<br />
				info@prolearning24.com
			</Override>
			<Override slot="text2">
				проспект Свободи, 5, Львів,{" "}
				<br />
				Львівська область, 79000
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f25879e74900024fead27"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});